import React from "react";
import {
  SEO,
  PageLayout,
  Section,
  FishermanHeader,
  MarkdownContent,
  OutboundLink,
  FishermanIcon,
  FadeReveal,
  BasicCarousel,
  componentIterator,
  Image,
  MultipleComponentIterator,
  SlideReveal,
  FullImageCard,
  InternalLink,
  Conditional,
  LocationName,
  Phone,
  Email,
  HoursOfOperation,
} from "@bluefin/components";
import { Grid, Button, Header, Card } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
  withNamedArgs,
  getBusinessFiles,
} from "../utils/utils";
import { createLocationSlug } from "@bluefin/gatsby-plugin-fisherman-page-creator";

export default class LocationsPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      bookingUrl,
      allFishermanBusinessLocation,
      allFishermanBusinessFile,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
          title={fishermanBusinessWebsitePage.seoTitle}
          ogUrl={"https://www.bon-bon.com/locations/"}
        />
        <PageLayout className={"locations-page"} hero={null}>
          <Section className={"default-hero-section"}>
            <Grid stackable={true}>
              <Grid.Column width={8} className={"text-column"}>
                <FishermanHeader
                  as={"h1"}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "locations_hero_header",
                    defaultValue: "Locations",
                  })}
                />
                <MarkdownContent
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "locations_hero_description",
                  })}
                />
                <Button
                  primary={true}
                  as={OutboundLink}
                  to={bookingUrl.url}
                  event={{
                    category: "Booking",
                    action: "Schedule Appointment Intent",
                  }}
                  icon={<FishermanIcon iconName={"arrow-right"} />}
                  labelPosition={"right"}
                  content={bookingUrl.title}
                />
              </Grid.Column>
              <Grid.Column width={8}>
                <FadeReveal triggerOnce={true}>
                  <BasicCarousel
                    className={"image-carousel"}
                    size={1}
                    slide={true}
                    displayIndicators={true}
                    displayPlayPauseButton={true}
                    autoScroll={false}
                    leftArrowIcon={"arrow-left"}
                    rightArrowIcon={"arrow-right"}
                    mobile={{ size: 1 }}
                    items={componentIterator({
                      iterator: getComponentFiles({
                        components: fishermanBusinessWebsitePage.components,
                        componentIdentifier: "hero_images",
                      }),
                      component: (
                        <Image background={true} loadStrategy={"eager"} />
                      ),
                      propMap: { src: "__all__" },
                    })}
                  />
                </FadeReveal>
              </Grid.Column>
            </Grid>
          </Section>
          <Section className={"location-cards-section"}>
            <Grid stackable={true}>
              <Grid.Column width={6} className={"info-column"}>
                <FishermanHeader
                  as={"h2"}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "locations_section_header",
                    defaultValue: "Our Locations",
                  })}
                />
                <FishermanHeader
                  as={"h3"}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "locations_section_subheader",
                  })}
                />
                <MarkdownContent
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "locations_section_description",
                  })}
                />
                <Button
                  primary={true}
                  as={OutboundLink}
                  to={bookingUrl.url}
                  event={{
                    category: "Booking",
                    action: "Schedule Appointment Intent",
                  }}
                  content={bookingUrl.title}
                  aria-label={"Book an Appointment"}
                />
              </Grid.Column>
              <Grid.Column width={10} className={"cards-column"}>
                <Card.Group itemsPerRow={3}>
                  <MultipleComponentIterator
                    iterator={allFishermanBusinessLocation.nodes}
                    components={[
                      {
                        component: (
                          <SlideReveal
                            className={"ui card"}
                            direction={"up"}
                            triggerOnce={true}
                            fade={true}
                          />
                        ),
                        propMap: { key: "_id" },
                        children: [
                          {
                            component: (
                              <FullImageCard
                                as={InternalLink}
                                link={true}
                                className={"location-card"}
                                event={{
                                  category: "Locations",
                                  action: "View Location Details",
                                }}
                              />
                            ),
                            propMap: {
                              "event.label": "name",
                              to: createLocationSlug,
                              image: withNamedArgs({
                                func: getBusinessFiles,
                                args: {
                                  businessFiles: allFishermanBusinessFile.nodes,
                                  numToSelect: 1,
                                  filesField: "featuredImage",
                                },
                              }),
                            },
                            children: [
                              {
                                component: (
                                  <div className={"header-container"} />
                                ),
                                children: [
                                  {
                                    component: <Header />,
                                    children: [
                                      {
                                        component: <Conditional />,
                                        children: [
                                          {
                                            component: (
                                              <Conditional.Condition />
                                            ),
                                            propMap: {
                                              condition: "name",
                                              children: "name",
                                            },
                                          },
                                          {
                                            component: (
                                              <Conditional.Condition />
                                            ),
                                            propMap: {
                                              condition: "city",
                                              children: "city",
                                            },
                                          },
                                          {
                                            component: <Conditional.Default />,
                                            propMap: { children: "street" },
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                  {
                                    component: (
                                      <Card.Meta content={"See Location"} />
                                    ),
                                  },
                                  {
                                    component: (
                                      <FishermanIcon
                                        iconName={"arrow-right"}
                                        className={"location-arrow-right"}
                                      />
                                    ),
                                  },
                                ],
                              },
                              {
                                component: (
                                  <div className={"location-details"} />
                                ),
                                children: [
                                  {
                                    component: <LocationName />,
                                    propMap: { "address.street": "street" },
                                  },
                                  {
                                    component: <LocationName />,
                                    propMap: {
                                      "address.city": "city",
                                      "address.state": "state",
                                      "address.zip": "zipCode",
                                    },
                                  },
                                  {
                                    component: <Phone />,
                                    propMap: { phone: "phoneNumber" },
                                  },
                                  {
                                    component: <Email />,
                                    propMap: { email: "email" },
                                  },
                                  {
                                    component: (
                                      <HoursOfOperation
                                        displayOption={"grouped"}
                                        inlineHours={true}
                                        truncateLabels={true}
                                      />
                                    ),
                                    propMap: { hours: "hours" },
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ]}
                  />
                </Card.Group>
              </Grid.Column>
            </Grid>
          </Section>
          <Section className={"services-section"}>
            <Grid stackable={true}>
              <Grid.Column width={9}>
                <FadeReveal duration={1250} triggerOnce={true} delay={0}>
                  <BasicCarousel
                    className={"image-carousel"}
                    size={1}
                    slide={true}
                    displayIndicators={false}
                    displayPlayPauseButton={true}
                    autoScroll={false}
                    leftArrowIcon={"arrow-left"}
                    rightArrowIcon={"arrow-right"}
                    mobile={{ size: 1 }}
                    items={componentIterator({
                      iterator: getComponentFiles({
                        components: fishermanBusinessWebsitePage.components,
                        componentIdentifier: "services_section_images",
                      }),
                      component: (
                        <Image
                          background={true}
                          className={"single-item-carousel-image"}
                        />
                      ),
                      propMap: { src: "__all__" },
                    })}
                  />
                </FadeReveal>
              </Grid.Column>
              <Grid.Column width={7} className={"content-column"}>
                <FadeReveal duration={1250} triggerOnce={true} delay={450}>
                  <div>
                    <FishermanHeader
                      as={"h2"}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentId: "services_section_header",
                        defaultValue: "Services",
                      })}
                    />
                    <FishermanHeader
                      as={"h3"}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentId: "services_section_subheader",
                        defaultValue: "Unlock Your Natural Radiance",
                      })}
                    />
                    <MarkdownContent
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentId: "services_section_description",
                      })}
                    />
                    <Button
                      primary={true}
                      as={InternalLink}
                      to={"/services/"}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentId: "services_section_see_all_services_button",
                        defaultValue: "Discover Our Services",
                      })}
                      event={{
                        category: "Services",
                        action: "View All Services",
                      }}
                    />
                  </div>
                </FadeReveal>
              </Grid.Column>
            </Grid>
          </Section>
          <Section className={"book-now-section"}>
            <Image
              background={true}
              src={getComponentFiles({
                components: fishermanBusinessWebsitePage.components,
                componentIdentifier: "book_now_background_image",
                numToSelect: 1,
              })}
            >
              <FishermanHeader
                as={"h2"}
                content={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "book_now_section_header",
                  defaultValue: "Book Your Appointment",
                })}
              />
              <MarkdownContent
                className={"book-now-description"}
                content={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "book_now_section_description",
                  defaultValue: "",
                })}
              />
              <Button
                primary={true}
                as={OutboundLink}
                to={bookingUrl.url}
                event={{
                  category: "Booking",
                  action: "Schedule Appointment Intent",
                }}
                icon={<FishermanIcon iconName={"arrow-right"} />}
                labelPosition={"right"}
                content={bookingUrl.title}
              />
            </Image>
          </Section>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Locations" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        contentNodes {
          content
        }
      }
      seoTitle
    }
    bookingUrl: fishermanBusinessWebsitePage(
      title: { regex: "/Book Now|Make An Appointment/" }
      pageType: { eq: "External" }
    ) {
      url
      title
    }
    allFishermanBusinessLocation {
      nodes {
        _id
        street
        city
        state
        zipCode
        name
        featuredImage
        email
        phoneNumber
        hours {
          day
          open
          close
        }
      }
    }
    allFishermanBusinessFile {
      nodes {
        _id
        file
        description
        gatsbyFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`;
